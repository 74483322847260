import classNames from 'classnames';
import React, { useState } from 'react';

import { OfflineScanType } from 'global/api/controller/BackofficeController';
import Api from 'global/api/platformApi';
import { isSuccessful } from 'global/api/platformApiHelpers';
import Tenant from 'model/Tenant';
import { BackOfficeScanRunningStatus } from 'screens/backoffice/BackofficeScreensConfig';
import BackofficeScanFilters from 'screens/backoffice/components/BackofficeScanFilters';
import OfflineScanButton from 'screens/backoffice/screens/offlineScans/OfflineScanButton/index';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useToggle } from 'utils/hooks';

import style from 'screens/backoffice/screens/offlineScans/style.module.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

type ScanResult = {
  scanRunningStatus: BackOfficeScanRunningStatus;
  scanType: OfflineScanType;
}

export default function BackofficeOfflineScansScreen() {
  const [secondsAmount, setSecondsAmount] = useState<number>(0);
  const [isCustomTimePeriodEnabled, toggleIsCustomTimePeriodEnabled] = useToggle(true);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [scanResult, setScanResult] = useState<null | ScanResult>(null);

  function getScanResultMsg({ scanRunningStatus, scanType }: ScanResult) {
    switch (scanRunningStatus) {
      case BackOfficeScanRunningStatus.SUCCESS:
        return `The ${scanType} scan has been triggered successfully`;
      case BackOfficeScanRunningStatus.FAILURE:
        return `An error has occurred in triggering the ${scanType} scan`;
      default:
        return '\u00a0';
    }
  }

  const getScanRunner = (scanType: OfflineScanType) => async () => {
    try {
      const tenantIds = selectedTenant ? [selectedTenant.id] : null;
      const totalTimeToScanInSeconds = isCustomTimePeriodEnabled ? secondsAmount : null;
      const response = await Api.Backoffice.OfflineScan.requestOfflineScan({
        scanType,
        tenantIds,
        totalTimeToScanInSeconds,
      });
      if (isSuccessful(response)) {
        return BackOfficeScanRunningStatus.SUCCESS;
      }
    } catch (err) {
      DebuggerConsole.error(`Backoffice - OfflineScan for ${scanType} Failed:`, err);
    }
    return BackOfficeScanRunningStatus.FAILURE;
  };

  async function handleClick(scanType: OfflineScanType) {
    const scanRunningStatus = await getScanRunner(scanType)();
    setScanResult({
      scanRunningStatus,
      scanType,
    });
  }

  return (
    <div className={backofficeStyles.backofficeInternalScreen}>
      <h2>
        Offline Scans
      </h2>
      <div>
        <BackofficeScanFilters
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
          isCustomTimePeriodEnabled={isCustomTimePeriodEnabled}
          toggleIsCustomTimePeriodEnabled={toggleIsCustomTimePeriodEnabled}
          setSecondsAmount={setSecondsAmount}
        />
        <div className={style.scanButtonsContainer}>
          {(Object.keys(OfflineScanType) as (keyof typeof OfflineScanType)[]).map((key) => (
            <OfflineScanButton
              key={key}
              scanType={OfflineScanType[key]}
              onClick={() => handleClick(OfflineScanType[key])}
            />
          ))}
          <div className={classNames(style.responseMessage, {
            [style.success]: scanResult?.scanRunningStatus === BackOfficeScanRunningStatus.SUCCESS,
            [style.failure]: scanResult?.scanRunningStatus === BackOfficeScanRunningStatus.FAILURE,
          })}
          >
            {scanResult && getScanResultMsg(scanResult)}
          </div>
        </div>
      </div>
    </div>
  );
}
