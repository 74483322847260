import React, { FC } from 'react';

import { UploadCSVRes } from 'global/api/controller/BackofficeController';

import style from 'screens/backoffice/screens/tenant/EditTenantScreen/OrgChartImportCSV/style.module.scss';
import editTenantStyles from 'screens/backoffice/screens/tenant/EditTenantScreen/style.module.scss';

type Props = {
  uploadCSVRes: UploadCSVRes;
};

const OrgChartImportResult: FC<Props> = ({ uploadCSVRes }) => (
  <>
    <h5>Results:</h5>
    {uploadCSVRes.successMsg && <span>{uploadCSVRes.successMsg}</span>}
    {uploadCSVRes.errors && uploadCSVRes.errors.length > 0 && (
      <div>
        <br />
        <h5>Errors:</h5>
        {uploadCSVRes.errors.map((err) => (
          <>
            <span className={editTenantStyles.error}>{err}</span>
            <br />
          </>
        ))}
      </div>
    )}
    {uploadCSVRes.warnings && uploadCSVRes.warnings.length > 0 && (
      <div>
        <br />
        <h5>Warnings:</h5>
        {uploadCSVRes.warnings.map((warning) => (
          <>
            <span className={style.warning}>{warning}</span>
            <br />
          </>
        ))}
      </div>
    )}
  </>
);

export default OrgChartImportResult;
