import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import Button from 'common-ui-components/Button';
import Table from 'common-ui-components/Table';
import { PersonWithSettingsData } from 'global/api/controller/PersonController';
import Api from 'global/api/platformApi';
import { isSuccessful } from 'global/api/platformApiHelpers';
import Role from 'global/lists/Role';
import Tenant from 'model/Tenant';
import TenantSelection from 'screens/backoffice/components/TenantSelection';
import SlackMarkdownInstructions from 'screens/backoffice/screens/nudges/SlackMarkdownInstructions';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import StringUtils from 'utils/StringUtils';

import style from 'screens/backoffice/screens/nudges/style.module.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

enum Error {
  EMPTY_CONTENT = 'Cannot send a nudge with an empty content.',
  NO_USERS_SELECTED = 'At least one user must be selected.',
  FAILED_TO_SEND = 'Failed to send nudge.',
}

enum Success {
  NUDGE_SENT = 'Nudge sent successfully.',
}

const BackofficeNudgesScreen: FC = () => {
  const { tenant } = useTenantContext();
  const { roles } = useUserContext();

  const [currentTenantPersons, setCurrentTenantPersons] = useState<Record<
    string,
    PersonWithSettingsData
  > | null>(null);
  const [selectedTenant, setSelectedTenant] = useState<Tenant>(tenant);
  const [selectedPersonIds, setSelectedPersonIds] = useState<string[]>([]);
  const [nudgeContent, setNudgeContent] = useState('');
  const [sending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Error | null>(null);
  const [successMessage, setSuccessMessage] = useState<Success | null>(null);

  useEffect(() => {
    const fetchTenantPersons = async () => {
      setCurrentTenantPersons(null);
      try {
        const res = await Api.Backoffice.Person.getPersonsByTenant(
          selectedTenant.id,
        );
        if (res?.data) {
          setCurrentTenantPersons(res.data);
        }
      } catch (err) {
        DebuggerConsole.error(err);
      }
    };

    fetchTenantPersons();
  }, [selectedTenant]);

  useEffect(() => {
    setErrorMessage(null);
    setSuccessMessage(null);
  }, [selectedPersonIds, nudgeContent]);

  const onSendClick = async () => {
    if (nudgeContent.length === 0) {
      setErrorMessage(Error.EMPTY_CONTENT);
      return;
    }
    if (selectedPersonIds.length === 0) {
      setErrorMessage(Error.NO_USERS_SELECTED);
      return;
    }

    setSending(true);
    try {
      const res = await Api.Backoffice.Nudge.sendNudge(
        selectedTenant.id,
        selectedPersonIds,
        nudgeContent,
      );
      if (isSuccessful(res)) {
        setSuccessMessage(Success.NUDGE_SENT);
      } else {
        setErrorMessage(Error.FAILED_TO_SEND);
      }
    } catch (err) {
      DebuggerConsole.error(err);
      setErrorMessage(Error.FAILED_TO_SEND);
    } finally {
      setSending(false);
    }
  };

  const personsArray = useMemo(() => {
    if (!currentTenantPersons) return [];
    return Object.values(currentTenantPersons)
      .sort((a, b) => StringUtils.alphabeticalDescendingSorter(a.email, b.email));
  }, [currentTenantPersons]);

  if (!roles?.includes(Role.NUDGER)) {
    return (
      <div>
        You don&apos;t have permissions to send nudges 😭
      </div>
    );
  }

  return (
    <div className={backofficeStyles.backofficeInternalScreen}>
      <h2>Nudges</h2>
      <div className={style.nudgesForm}>
        <div className={style.textAreaContainer}>
          <div className={backofficeStyles.tenantSelectionWrapper}>
            <label>Select Tenant:</label>
            <TenantSelection
              selectedTenantId={selectedTenant.id}
              setSelectedTenant={setSelectedTenant}
              nullable={false}
            />
          </div>
          <textarea
            placeholder="Nudge's content"
            value={nudgeContent}
            onChange={(e) => {
              setNudgeContent(e.target.value);
            }}
          />
          <Button
            color="blue"
            rounded
            loading={sending}
            padded
            onClick={onSendClick}
          >
            Send
          </Button>
          {successMessage && <span className={style.success}>{successMessage}</span>}
          {errorMessage && <span className={style.error}>{errorMessage}</span>}
          <SlackMarkdownInstructions />
        </div>
        <div className={style.personsTableContainer}>
          <label>Send to:</label>
          <Table
            className={style.personsTable}
            dataSource={personsArray}
            loading={!currentTenantPersons}
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedPersonIds,
              onChange: (selectedIds) => setSelectedPersonIds(selectedIds as string[]),
            }}
            columns={[
              {
                title: 'User',
                key: 'User',
                render: (_, { email }) => email,
                sorting: {
                  byComparator: (a, b) =>
                    StringUtils.alphabeticalDescendingSorter(a.email, b.email),
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default BackofficeNudgesScreen;
