import React, { useState } from 'react';
import { useAsync } from 'react-use';

import Button from 'common-ui-components/Button';
import Input from 'common-ui-components/Input';
import SearchableSelectionDropdown from 'common-ui-components/SearchableSelectionDropdown';
import Spinner from 'common-ui-components/Spinner';
import Api from 'global/api/platformApi';
import Person from 'model/Person';
import Tenant from 'model/Tenant';
import TenantSelection from 'screens/backoffice/components/TenantSelection';
import { SessionSearchConfig } from 'screens/backoffice/screens/GPTSessions/index';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import ArrayUtils from 'utils/ArrayUtils';
import ToastManager from 'utils/ToastManager';

import style from 'screens/backoffice/screens/GPTSessions/style.module.scss';

interface Filter {
  label: string;
  loading: boolean;
  component: JSX.Element;
}

interface Props {
  search: (config: SessionSearchConfig) => void;
  isLoading: boolean;
}

export default function GPTSessionBackofficeConfiguration({ search, isLoading }: Props) {
  const { tenant } = useTenantContext();
  const [selectedTenant, setSelectedTenant] = useState<Tenant>(tenant);
  const [selectedPersonId, setSelectedPersonId] = useState<Person['id'] | undefined>(undefined);
  const [selectedSessionId, setSelectedSessionId] = useState<string | undefined>(undefined);

  const { value: peopleNamesOptions, loading: isLoadingPeople } = useAsync(async () => {
    const showError = () => ToastManager.show('An error has occurred during people fetching', { key: 'people fetching' });
    const allPeopleMetadata = await Api.Person.getAllPeopleMetadata(selectedTenant.id);
    if (!allPeopleMetadata?.data) {
      showError();
      return [];
    }
    const allPeopleMap = await Api.Person.getPeopleByIds(
      selectedTenant.id,
      Object.keys(allPeopleMetadata.data.allPeopleMetadata),
    );

    if (!allPeopleMap?.data) {
      showError();
      return [];
    }

    const peopleListOptions = Object.values(allPeopleMap.data)
      .filter(ArrayUtils.isDefined)
      .sort(ArrayUtils.sortByFunc((p) => p.name.toLowerCase()))
      .map(({ id, name }) => ({ value: id, label: name }));

    return peopleListOptions;
  }, [selectedTenant.id]);

  const filters: Filter[] = [
    {
      label: 'Tenant',
      loading: false,
      component: <TenantSelection
        selectedTenantId={selectedTenant?.id}
        setSelectedTenant={setSelectedTenant}
      />,
    },
    {
      label: 'Person',
      loading: isLoadingPeople,
      component: <SearchableSelectionDropdown
        options={peopleNamesOptions || null}
        selectedValue={selectedPersonId}
        selectValue={setSelectedPersonId}
      />,
    },
    {
      label: 'SessionId',
      loading: false,
      component: <Input
        value={selectedSessionId || undefined}
        setValue={setSelectedSessionId}
        placeholder="Optional"
      />,
    },
  ];

  const onClick = () => search({
    tenantId: selectedTenant.id,
    personId: selectedPersonId,
    sessionId: selectedSessionId,
  });

  return (
    <div className={style.configuration}>
      <div className={style.filters}>
        {filters.map(({ label, component, loading }) => (
          <div key={label} className={style.filter}>
            <label>{label}</label>
            {loading ? <Spinner /> : component}
          </div>
        ))}
      </div>
      <Button color="blue" padded rounded disabled={isLoading} onClick={onClick}>
        Search
      </Button>
    </div>
  );
}
