import React, { useMemo } from 'react';
import { useAsync } from 'react-use';

import Spinner from 'common-ui-components/Spinner';
import { SessionResponse } from 'global/api/controller/BackofficeController';
import Api from 'global/api/platformApi';
import GPTSessionCard from 'screens/backoffice/screens/GPTSessions/GPTSessionsList/GPTSessionCard';

import style from 'screens/backoffice/screens/GPTSessions/GPTSessionsList/style.module.scss';

interface Props {
  sessions: SessionResponse[] | null;
  isLoading: boolean;
}

export default function GPTSessions({ sessions, isLoading }: Props) {
  const { value: personsMap, loading: loadingPersonsMap } = useAsync(async () => {
    if (sessions === null || sessions.length === 0) return {};
    const { tenantId } = sessions[0];
    const response = await Api.Person.getPeopleByIds(
      tenantId,
      sessions.map(({ personId }) => personId),
    );
    return response?.data || {};
  }, [sessions]);

  const sortedSessions = useMemo(
    () =>
      sessions?.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    [sessions],
  );

  return isLoading || loadingPersonsMap || !personsMap ? <Spinner /> : (
    <div className={style.container}>
      {sortedSessions && sortedSessions.map((session) =>
        <GPTSessionCard key={session.id} session={session} personsMap={personsMap} />)}
    </div>
  );
}
