import React from 'react';

import style from 'screens/backoffice/screens/GPTSessions/GPTSessionsList/GPTSessionCard/style.module.scss';

interface Props {
  title: string;
  content: string;
}

export default function CardSection({ title, content }: Props) {
  return (
    <div className={style.section}>
      <div className={style.title}>{title}</div>
      <div className={style.content}>{content}</div>
    </div>
  );
}
