import React, { useEffect, useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import Button from 'common-ui-components/Button';
import CopiableText from 'common-ui-components/CopiableText';
import Table from 'common-ui-components/Table';
import Api from 'global/api/platformApi';
import Tenant from 'model/Tenant';
import AddTenantModal from 'screens/backoffice/screens/tenant/AddTenantModal';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import StringUtils from 'utils/StringUtils';

import { ReactComponent as PlusIcon } from 'assets/img/icon/plus.svg';

import styles from 'screens/backoffice/screens/tenant/style.module.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

export interface ParsedTenant extends Omit<Tenant, 'id'> {
  id: string;
}

export default function BackofficeTenantScreen() {
  const { allTenants, dispatchTenant } = useTenantContext();

  const [isAddTenantModalOpen, setIsAddTenantModalOpen] = useState<boolean>(false);
  const [isRefreshingTenants, setIsRefreshingTenants] = useState<boolean>(true);

  const { url } = useRouteMatch();

  const allTenantsParsed: ParsedTenant[] = useMemo(() => (
    allTenants?.map((tenant) => ({ ...tenant, id: tenant.id.toString() })) || []
  ), [allTenants]);

  useEffect(() => {
    async function fetchTenants() {
      try {
        const tenantsFromDB = await Api.Tenant.getAllTenants();
        if (tenantsFromDB?.data) {
          dispatchTenant({ type: 'SET_ALL_TENANTS', payload: { allTenants: tenantsFromDB.data } });
        }
      } catch (err) {
        // @ts-ignore
        DebuggerConsole.error(err.message);
      } finally {
        setIsRefreshingTenants(false);
      }
    }
    fetchTenants().then();
  }, []);

  const SPACE = '\u00a0';
  const BULLET = '\u2022';
  return (
    <div className={backofficeStyles.backofficeInternalScreen}>
      <h2>
        Tenants
      </h2>
      <div>
        <AddTenantModal
          isOpen={isAddTenantModalOpen}
          setIsOpen={setIsAddTenantModalOpen}
          allTenants={allTenantsParsed}
        />
        <div className={backofficeStyles.headerSection}>
          <Button
            color="blue"
            padded
            rounded
            className={backofficeStyles.addButton}
            onClick={() => setIsAddTenantModalOpen(true)}
          >
            <PlusIcon color="white" width={15} height={15} />
            Add new tenant
          </Button>
        </div>
        <Table<ParsedTenant>
          className={styles.tableWrapper}
          dataSource={allTenantsParsed}
          loading={isRefreshingTenants}
          emptyDataMsg="No tenants"
          rowKey="id"
          columns={[
            {
              title: 'ID',
              key: 'ID',
              render: (_, tenant) => <CopiableText text={tenant.id} />,
              width: 130,
              sorting: {
                byComparator: (a, b) => StringUtils.alphabeticalDescendingSorter(a.id, b.id),
              },
            }, {
              title: 'Name',
              key: 'Name',
              render: (_, tenant) => tenant.name,
              width: 200,
              sorting: {
                byComparator: (a, b) => StringUtils.alphabeticalDescendingSorter(a.name, b.name),
              },
            }, {
              title: 'Departments',
              key: 'Departments',
              minWidth: 400,
              render: (_, tenant) => (
                <div className={styles.departments}>
                  { `${Object.keys(tenant.departments).join(` ${BULLET} `)} ${Object.keys(tenant.departments).length > 0 ? SPACE : ''}` }
                </div>
              ),
            }, {
              title: 'Status',
              key: 'Status',
              render: (_, tenant) => tenant.setupState,
              width: 120,
            }, {
              title: '',
              key: '',
              render: (_, tenant) => (
                <Link
                  to={`${url}/${tenant.id}`}
                  className={styles.departmentEdit}
                >
                  Edit
                </Link>
              ),
              width: 60,
            },
          ]}
        />
      </div>
    </div>
  );
}
