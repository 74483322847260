import React, { Dispatch, SetStateAction } from 'react';

import Button from 'common-ui-components/Button';
import SearchableSelectionDropdown from 'common-ui-components/SearchableSelectionDropdown';
import Spinner from 'common-ui-components/Spinner';
import Table from 'common-ui-components/Table';
import { Pagination } from 'common-ui-components/Table/paginationHook';
import Size from 'global/lists/Size';
import Tag from 'model/Tag';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

import style from 'screens/backoffice/screens/suggestedTags/SuggestedTagsTable/style.module.scss';

type Props = {
  data: Tag[];
  isLoading: boolean;
  pagination: Pagination;
  setSelectedTags: Dispatch<SetStateAction<string[]>>;
  handleCategoryChange: (tagToUpdate: Tag, nextCategory: string) => void;
  removeTag: (tagToRemove: string) => void;
};

export default function SuggestedTagsTable({
  data,
  isLoading,
  pagination,
  setSelectedTags,
  handleCategoryChange,
  removeTag,
}: Props) {
  const { tagsToCategories, categories: allCategories } = useMetadataContext();

  const checkShouldBeDisabled = (potentialTag: string) =>
    Object.keys(tagsToCategories).includes(potentialTag.toUpperCase())
    || Object.keys(allCategories).includes(potentialTag.toUpperCase());

  return (
    <Table<Tag>
      dataSource={data}
      loading={isLoading}
      emptyDataMsg="No suggested tags"
      rowKey="value"
      pagination={pagination}
      rowSelection={{
        onChange: (selectedTags) => setSelectedTags(selectedTags as string[]),
        getCheckboxProps: (tag) => ({
          disabled: checkShouldBeDisabled(tag.value),
        }),
      }}
      columns={[
        {
          title: 'Tag',
          key: 'Tag',
          width: 230,
          render: (_, tag) => (
            <div title={tag.value} className={style.tagWrapper}>
              {tag.value}
            </div>
          ),
        },
        {
          title: 'Category',
          key: 'Category',
          width: 300,
          render: (_, tag) => {
            if (tagsToCategories === null) {
              return (
                <div className="loading">
                  <Spinner size={Size.SMALL} />
                </div>
              );
            }

            const shouldBeDisabled = checkShouldBeDisabled(tag.value);
            if (shouldBeDisabled) {
              const categoryIfTagExists = tagsToCategories[tag.value.toUpperCase()];
              return `Already exists under "${categoryIfTagExists}" category`;
            }
            let options = (Object.keys(allCategories) || []).map((c) => ({
              value: c,
              label: c,
            }));
            const tagCategory = tag.categories[0]?.toUpperCase();
            if (!Object.keys(allCategories).includes(tagCategory)) {
              options = [
                { value: tagCategory, label: tagCategory },
                ...options,
              ];
            }
            return (
              <SearchableSelectionDropdown
                options={options}
                selectedValue={tagCategory}
                selectValue={(category: string) =>
                  handleCategoryChange(tag, category)}
              />
            );
          },
        },
        {
          title: 'Actions',
          key: 'Actions',
          render: (_, tag) => {
            const categoryIfTagExists = checkShouldBeDisabled(tag.value);
            return categoryIfTagExists ? null : (
              <Button
                className={style.removeTag}
                rounded
                title="Not a tag"
                onClick={() => removeTag(tag.value)}
              >
                {'\u00D7'}
                <span className={style.buttonText}>Remove</span>
              </Button>
            );
          },
        },
      ]}
    />
  );
}
