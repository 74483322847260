import React from 'react';

import Checkbox from 'common-ui-components/Checkbox';
import App from 'global/lists/apps';
import getAppDisplayName from 'global/lists/appsNames';
import { appsToScanDataTypes } from 'screens/backoffice/screens/ods/OdsUtils';

export default function AppOptionsRow({
  appId, checked, partiallyChecked, dataTypes, onAppToggle, onDataTypeToggle,
}: {
  appId: App;
  checked: boolean;
  partiallyChecked: boolean;
  dataTypes: Record<string, boolean>;
  onAppToggle: () => void;
  onDataTypeToggle: (dataType: string) => void;
}) {
  const label = getAppDisplayName(appId);

  return (
    <>
      <Checkbox
        className="app-label"
        label={label}
        checked={checked}
        partiallyChecked={partiallyChecked}
        onClick={onAppToggle}
      />
      {
        Object.entries(dataTypes).map(([dataType, isDataTypeChecked]) => (
          <Checkbox
            key={dataType}
            label={`${dataType} (${appsToScanDataTypes[appId][dataType]})`}
            checked={isDataTypeChecked}
            onClick={() => onDataTypeToggle(dataType)}
            showTitle
          />
        ))
      }
    </>
  );
}
