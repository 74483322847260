import React, { FC, useMemo } from 'react';

import Button from 'common-ui-components/Button/index';
import Input from 'common-ui-components/Input/index';
import Spinner from 'common-ui-components/Spinner';
import { AkoodaGPTResponse } from 'global/api/controller/BackofficeController';
import Api from 'global/api/platformApi';
import AkoodaGPTContent from 'screens/backoffice/screens/akoodaGPT/AkoodaGPTContent';
import { useMountedState } from 'utils/hooks';

import style from 'screens/backoffice/screens/akoodaGPT/style.module.scss';

const AkoodaGPTScreen: FC = () => {
  const [question, setQuestion] = useMountedState('');
  const [loading, setLoading] = useMountedState(false);
  const [data, setData] = useMountedState<AkoodaGPTResponse | undefined>(undefined);

  async function getAnswer() {
    setLoading(true);

    try {
      const response = await Api.Backoffice.AkoodaGPT.getAnswer(question);
      if (response?.data) {
        setData(response.data);
      }
    } catch (err) {
      setData({ answer: '', atoms: [], error: err!.toString() });
    } finally {
      setLoading(false);
    }
  }

  const answerContent = useMemo(() => {
    if (loading) return <Spinner />;
    if (data?.error) return <span>{data.error}</span>;
    if (data?.answer === undefined) return null;

    return <AkoodaGPTContent answer={data.answer} atoms={data?.atoms} />;
  }, [loading, data]);

  return (
    <div className={style.screen}>
      <h2>Akooda GPT</h2>
      <div className={style.content}>
        <div className={style.search}>
          <Input
            value={question}
            setValue={setQuestion}
            placeholder="Ask me anything"
            className={style.input}
            onClickEnter={getAnswer}
          />
          <Button color="blue" padded rounded onClick={getAnswer} disabled={loading}>
            Ask
          </Button>
        </div>
        <div className={style.answer}>
          {answerContent}
        </div>
      </div>
    </div>
  );
};

export default AkoodaGPTScreen;
