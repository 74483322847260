import { format } from 'date-fns';
import React, { } from 'react';

import { LanguageModel, SessionResponse, SessionType } from 'global/api/controller/BackofficeController';
import Person from 'model/Person';
import CardSection from 'screens/backoffice/screens/GPTSessions/GPTSessionsList/GPTSessionCard/CardSection';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DateUtils from 'utils/DateUtils';

import { ReactComponent as OpenAIIcon } from 'assets/img/logo/open-ai.svg';
import { ReactComponent as VertexAIIcon } from 'assets/img/logo/vertex-ai.svg';

import style from 'screens/backoffice/screens/GPTSessions/GPTSessionsList/GPTSessionCard/style.module.scss';

interface Props {
  session: SessionResponse;
  personsMap: Record<string, Person>;
}

const sessionTypeNameMapper: Record<SessionType, string> = {
  [SessionType.INTERACTIONS]: 'Atoms Summary',
  [SessionType.TOPIC]: 'Slack Bot Whatis',
  [SessionType.ORGANIZATION]: 'Slack Bot Whois',
};

export default function GPTSessionCard({ session, personsMap }: Props) {
  const { allTenants } = useTenantContext();
  const {
    answer,
    personId,
    type,
    createdAt,
    prompt,
    feedback,
    tenantId,
    languageModel,
    responseTime,
    isShadow,
  } = session;

  const formattedDate = format(createdAt, DateUtils.DateFormat.WEEKDAY_MONTH_DAY_TH);
  const responseTimeInSeconds = responseTime / 1000;
  const feedbackSectionTitle = `Feedback ${feedback?.type ? `(${feedback?.type})` : ''}:`;
  const feedbackSectionContent = feedback?.content || 'No Feedback';
  const tenantName = allTenants?.find(({ id }) => id === tenantId)?.name;
  const personName = personsMap[personId]?.name;
  const personDisplayName = tenantName ? `${personName} (${tenantName})` : personName;
  const ModelLogo = languageModel === LanguageModel.OpenAI ? OpenAIIcon : VertexAIIcon;

  return (
    <div className={style.card}>
      <div className={style.title}>
        <ModelLogo width={70} height={50} />
        <div>{personDisplayName}</div>
        <div className={style.type}>{sessionTypeNameMapper[type]}</div>
        <div className={style.date}>{formattedDate}</div>
        <div>{`Shadow mode: ${isShadow}`}</div>
      </div>
      <div className={style.sections}>
        <CardSection title="Prompt:" content={prompt} />
        <CardSection title="Answer:" content={answer} />
        <CardSection title="Execution Time:" content={`${responseTimeInSeconds} seconds`} />
        <CardSection title={feedbackSectionTitle} content={feedbackSectionContent} />
      </div>
    </div>
  );
}
