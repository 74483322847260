import React from 'react';

export default function IgnoredSendersInput({
  initialValue,
  disabled,
  setIgnoredSenders,
}) {
  return (
    <textarea
      value={initialValue}
      onChange={(e) => setIgnoredSenders(e.target.value)}
      disabled={disabled}
    />
  );
}
