import React from 'react';

import App from 'global/lists/apps';
import AppOptionsRow from 'screens/backoffice/screens/ods/ConnectorsScansRows/AppOptionsRow';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

export default function ConnectorsScansRows({ config, rerender }: {
  config: null | HierarchicalGroup;
  rerender: () => void;
}) {
  function onAppToggle(appId: App) {
    if (config) {
      const group = config.getChild(appId.toString());
      if (group) {
        group.toggle();
        rerender();
      }
    }
  }

  function onDataTypeToggle(appId: App, dataType: string) {
    if (config) {
      const group = config.getChild(appId.toString());
      if (group?.isParent()) {
        const dataTypeItem = group.getChild(dataType);
        if (dataTypeItem) {
          dataTypeItem.toggle();
          rerender();
        }
      }
    }
  }

  return (
    <>
      {
        config?.getChildrenEntries().map(([appId, group]) => {
          if (group && group.isParent()) {
            const childrenSelectionStatus = group.getChildrenEntries()
              .reduce((acc, [dataType, item]) => ({
                ...acc,
                [dataType]: item.isSelected,
              }), {});
            return (
              <AppOptionsRow
                key={appId}
                appId={Number(appId)}
                checked={group.isSelected}
                partiallyChecked={group.isPartiallySelected}
                dataTypes={childrenSelectionStatus}
                onAppToggle={() => onAppToggle(Number(appId))}
                onDataTypeToggle={(dataType) => onDataTypeToggle(Number(appId), dataType)}
              />
            );
          }
          return null;
        })
      }
    </>
  );
}
