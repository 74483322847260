import React from 'react';

import { Atom } from 'model/Atom';
import TypeWriter
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/TypeWriter';
import useInteractionsTableColumns
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsHook';
import AtomsTable
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/AtomsTable';

import style from 'screens/backoffice/screens/akoodaGPT/style.module.scss';

interface Props {
  answer: string;
  atoms: Atom[] | undefined;
}

export default function AkoodaGPTContent({ answer, atoms }: Props) {
  const { columns } = useInteractionsTableColumns();

  return (
    <>
      <div>
        <div className={style.answerTitle}>
          Answer
        </div>
        <h4>
          <TypeWriter text={answer} />
        </h4>
      </div>
      {
        atoms?.length && atoms.length > 0 ? (
          <div className={style.atoms}>
            <AtomsTable
              dataSource={atoms}
              loading={false}
              emptyDataMsg="No atoms"
              columns={columns}
            />
          </div>
        ) : null
      }
    </>
  );
}
