import React, { useState } from 'react';

import Button from 'common-ui-components/Button/index';
import Spinner from 'common-ui-components/Spinner';
import { OfflineScanType } from 'global/api/controller/BackofficeController';
import Size from 'global/lists/Size';

import style from 'screens/backoffice/screens/offlineScans/OfflineScanButton/style.module.scss';

interface OfflineScanButtonProps {
  scanType: OfflineScanType;
  onClick: () => Promise<void>;
}

export default function OfflineScanButton({ scanType, onClick }: OfflineScanButtonProps) {
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true);
    await onClick();
    setLoading(false);
  }

  return (
    <div className={style.buttonContainer}>
      <Button color="blue" className={style.scanButton} onClick={handleClick} disabled={loading}>
        {`Run ${scanType} Scan`}
        {loading && <Spinner size={Size.SMALL} /> }
      </Button>
    </div>
  );
}
