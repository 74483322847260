import React, { Dispatch, SetStateAction } from 'react';

import Checkbox from 'common-ui-components/Checkbox';
import Tenant from 'model/Tenant';
import DatePickerDropdownButton from 'screens/backoffice/components/DatePickerDropdownButton';
import TenantSelection from 'screens/backoffice/components/TenantSelection';

import styles from 'screens/backoffice/components/BackofficeScanFilters/style.module.scss';

type Props = {
  selectedTenant: Tenant | null;
  setSelectedTenant: Dispatch<SetStateAction<Tenant | null>>;
  isCustomTimePeriodEnabled: boolean;
  toggleIsCustomTimePeriodEnabled: () => void;
  setSecondsAmount: Dispatch<SetStateAction<number>>;
}

export default function BackofficeScanFilters({
  selectedTenant,
  setSelectedTenant,
  isCustomTimePeriodEnabled,
  toggleIsCustomTimePeriodEnabled,
  setSecondsAmount,
}: Props) {
  return (
    <div>
      <div className={styles.globalFilter}>
        <label>
          For Tenant:
        </label>
        <TenantSelection
          selectedTenantId={selectedTenant?.id || null}
          setSelectedTenant={setSelectedTenant}
          nullable
        />
      </div>
      <div className={styles.globalFilter}>
        <label>
          Custom Time Period:
        </label>
        <div className={styles.customTimePeriodFilters}>
          <Checkbox label="" checked={isCustomTimePeriodEnabled} onClick={toggleIsCustomTimePeriodEnabled} />
          &nbsp;&nbsp;&nbsp;
          <DatePickerDropdownButton
            setSecondsAmount={setSecondsAmount}
            disabled={!isCustomTimePeriodEnabled}
          />
        </div>
      </div>
    </div>
  );
}
