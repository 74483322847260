import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import Button from 'common-ui-components/Button';
import Api from 'global/api/platformApi';
import { isSuccessful } from 'global/api/platformApiHelpers';
import Tenant from 'model/Tenant';
import { BackOfficeScanRunningStatus } from 'screens/backoffice/BackofficeScreensConfig';
import BackofficeScanFilters from 'screens/backoffice/components/BackofficeScanFilters';
import ConnectorsScansRows from 'screens/backoffice/screens/ods/ConnectorsScansRows';
import OdsUtils, { useAppsScanConfig } from 'screens/backoffice/screens/ods/OdsUtils';
import { useToggle } from 'utils/hooks';

import 'screens/backoffice/screens/ods/style.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

export default function BackofficeOdsScreen() {
  const [secondsAmount, setSecondsAmount] = useState<number>(0);
  const [selectedTenant, setSelectedTenant] = useState<null | Tenant>(null);
  const [isCustomTimePeriodEnabled, toggleIsCustomTimePeriodEnabled] = useToggle(true);

  const [, rerender] = useToggle(false);
  const [runningStatus, setRunningStatus] = useState<null | BackOfficeScanRunningStatus>(null);
  const appsScansConfig = useAppsScanConfig(selectedTenant, setRunningStatus);

  async function requestOdsScans() {
    if (appsScansConfig) {
      try {
        const requestConfig = OdsUtils.getAppsToDataTypes(appsScansConfig);

        if (selectedTenant?.id === null) {
          // eslint-disable-next-line no-alert
          alert('Please select a specific tenant, so we don\'t block our ODS pipeline');
          setRunningStatus(BackOfficeScanRunningStatus.FAILURE);
        }
        const responses = await Api.Backoffice.Ods.requestOdsScans({
          tenantId: selectedTenant?.id.toString() || null,
          config: requestConfig,
          areAllAppsSelected: appsScansConfig.isSelected,
          totalTimeToScanInSeconds: isCustomTimePeriodEnabled ? secondsAmount : null,
        });
        setRunningStatus(responses.every(isSuccessful)
          ? BackOfficeScanRunningStatus.SUCCESS
          : BackOfficeScanRunningStatus.FAILURE);
      } catch (err) {
        setRunningStatus(BackOfficeScanRunningStatus.FAILURE);
      }
    }
  }

  const responseMsg = (() => {
    switch (runningStatus) {
      case BackOfficeScanRunningStatus.SUCCESS:
        return 'Tasks were ran successfully';
      case BackOfficeScanRunningStatus.FAILURE:
        return 'An error has occurred';
      default:
        return '\u00a0';
    }
  })();

  const selectAll = useCallback((selected: boolean) => {
    if (selected) {
      appsScansConfig?.select();
    } else {
      appsScansConfig?.deselect();
    }
    rerender();
  }, [appsScansConfig, rerender]);

  return (
    <div className={backofficeStyles.backofficeInternalScreen}>
      <h2>
        On-Demand Tasks
      </h2>
      <div>
        <BackofficeScanFilters
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
          isCustomTimePeriodEnabled={isCustomTimePeriodEnabled}
          toggleIsCustomTimePeriodEnabled={toggleIsCustomTimePeriodEnabled}
          setSecondsAmount={setSecondsAmount}
        />
        <div className="select-all-container">
          <Button className="select-all" onClick={() => selectAll(true)} disabled={runningStatus !== null}>
            Select all
          </Button>
          <Button className="select-all" onClick={() => selectAll(false)} disabled={runningStatus !== null}>
            Deselect all
          </Button>
        </div>
        <div id="backoffice-ods-menu">
          <div id="backoffice-ods-options">
            {
              ['App', 'Data Types'].map((title) => (
                <div key={title} className="header">{ title }</div>
              ))
            }
            <ConnectorsScansRows config={appsScansConfig} rerender={rerender} />
          </div>
          <div className="right-section">
            <Button id="run-task" color="blue" onClick={requestOdsScans} disabled={runningStatus !== null}>
              Run Tasks
            </Button>
            <div className={classNames('response-message', {
              success: runningStatus === BackOfficeScanRunningStatus.SUCCESS,
              failure: runningStatus === BackOfficeScanRunningStatus.FAILURE,
            })}
            >
              { responseMsg }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
