import React, {
  Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';

import Button from 'common-ui-components/Button';
import DatePickerDropdown from 'common-ui-components/DatePickerDropdown';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import subMonths from 'date-fns/subMonths';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { getDatesFilterLabel } from 'utils/DateUtils';

export default function DatePickerDropdownButton({ setSecondsAmount, disabled }:
    { setSecondsAmount: Dispatch<SetStateAction<number>>; disabled: boolean }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [datesRange, setDatesRange] = useState<DatesRange>(
    { from: subMonths(new Date(), 3), to: new Date() },
  );
  const dropdownToggleButton = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const SECONDS_IN_DAY = 24 * 60 * 60;
    setSecondsAmount(
      differenceInSeconds(datesRange.to, datesRange.from) + SECONDS_IN_DAY,
    );
  }, [datesRange]);

  return (
    <DatePickerDropdown
      datesRange={datesRange}
      setDatesRange={setDatesRange}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      toggleButtonElement={(
        <Button
          color="blue"
          padded
          rounded
          disabled={disabled}
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          ref={dropdownToggleButton}
        >
          { getDatesFilterLabel(datesRange) }
        </Button>
      )}
    />
  );
}
