import classNames from 'classnames';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import InternalLink from 'common-ui-components/InternalLink/index';
import { getScreenPath } from 'global/ScreensConfiguration';
import { BackofficeScreensConfig } from 'screens/backoffice/BackofficeScreensConfig';
import LeftPanel from 'screens/platform/cross-platform-components/LeftPanel';

import style from 'screens/backoffice/BackofficeMenu/style.module.scss';

export default function BackofficeMenu() {
  const location = useLocation();

  return (
    <LeftPanel>
      <div className={style.backofficeMenu}>
        {
          Object.values(BackofficeScreensConfig)
            .map((panel) => {
              const { screenKey, label } = panel;
              const isActive = matchPath(location.pathname, getScreenPath(screenKey));
              return (
                <InternalLink
                  key={screenKey}
                  to={screenKey}
                  className={classNames(style.link, { [style.active]: isActive })}
                >
                  { label }
                </InternalLink>
              );
            })
        }
      </div>
    </LeftPanel>
  );
}
