import React, { FC, useRef } from 'react';

import Button from 'common-ui-components/Button';
import { UploadCSVRes } from 'global/api/controller/BackofficeController';
import Api from 'global/api/platformApi';
import Tenant from 'model/Tenant';
import OrgChartImportResult from 'screens/backoffice/screens/tenant/EditTenantScreen/OrgChartImportCSV/OrgChartImportResult';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

import style from 'screens/backoffice/screens/tenant/EditTenantScreen/OrgChartImportCSV/style.module.scss';
import editTenantStyles from 'screens/backoffice/screens/tenant/EditTenantScreen/style.module.scss';

const ACCEPTED_FILE_TYPES = 'text/csv';

enum GeneralError {
  NO_FILE_SELECTED = 'No CSV file selected.',
  FAILED_TO_UPLOAD_FILE = 'Failed to upload file',
}

type Props = {
  tenantId: Tenant['id'];
};

const OrgChartImportCSV: FC<Props> = ({ tenantId }) => {
  const [selectedFile, setSelectedFile] = useMountedState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [uploadCSVRes, setUploadCSVRes] = useMountedState<UploadCSVRes | null>(null);
  const [generalError, setGeneralError] = useMountedState<GeneralError | null>(null);
  const [loading, setLoading] = useMountedState(false);

  const sendData = async () => {
    setUploadCSVRes(null);

    if (!selectedFile) {
      setGeneralError(GeneralError.NO_FILE_SELECTED);
      return;
    }

    try {
      setLoading(true);
      const res = await Api.Backoffice.Person.uploadOrgChart(
        tenantId,
        selectedFile,
      );
      if (res?.data) {
        setUploadCSVRes(res.data);
      } else {
        throw new Error(GeneralError.FAILED_TO_UPLOAD_FILE);
      }
    } catch (error) {
      DebuggerConsole.error(error);
      // @ts-ignore
      setGeneralError(error.message);
    } finally {
      setLoading(false);
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
      setSelectedFile(null);
    }
  };

  function onFileSelect(event) {
    const file = event.target.files?.item(0);
    if (file) {
      setSelectedFile(file);
      setGeneralError(null);
      setUploadCSVRes(null);
    }
  }

  return (
    <div>
      <h4 className={editTenantStyles.featureHeader}>Upload Org Chart CSV</h4>
      <div className={style.inputWrapper}>
        <input
          ref={inputFileRef}
          type="file"
          accept={ACCEPTED_FILE_TYPES}
          onChange={onFileSelect}
        />
        <Button
          onClick={sendData}
          color="blue"
          className={editTenantStyles.actionBtn}
          loading={loading}
        >
          Upload
        </Button>
      </div>
      {generalError && (
        <span className={editTenantStyles.error}>{generalError}</span>
      )}
      {uploadCSVRes && <OrgChartImportResult uploadCSVRes={uploadCSVRes} />}
    </div>
  );
};

export default OrgChartImportCSV;
