import React, { useEffect, useState } from 'react';

import Button from 'common-ui-components/Button';
import Api from 'global/api/platformApi';
import { AKOODA_TENANT_ID } from 'global/constants';
import IgnoredSendersInput from 'screens/backoffice/screens/emailApp/IgnoredSendersInput';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import ToastManager from 'utils/ToastManager';

import style from 'screens/backoffice/screens/emailApp/style.module.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

export default function BackofficeEmailAppScreen() {
  const { endUserSession } = useUserContext();
  const [loadingDeleteInboxContent, setLoadingDeleteInboxContent] = useState(false);
  const [ignoredSendersLoading, setIgnoredSendersLoading] = useState(true);
  const [ignoredSenders, setIgnoredSenders] = useState('');

  useEffect(() => {
    async function getInitialIgnoredSenders() {
      try {
        const response = await Api.Backoffice.EmailApp.getIgnoredSenders(AKOODA_TENANT_ID);
        if (response?.data) {
          setIgnoredSenders(response.data);
        }
      } catch (err) {
        DebuggerConsole.error('Failed to get ignored senders', err);
      } finally {
        setIgnoredSendersLoading(false);
      }
    }

    getInitialIgnoredSenders();
  }, []);

  async function deleteInboxContent() {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const ans = confirm('Are you sure you want to delete all inbox content');

    if (ans) {
      setLoadingDeleteInboxContent(true);
      try {
        await Api.Backoffice.EmailApp.deleteInboxContent(AKOODA_TENANT_ID);
        ToastManager.show('Deleted inbox content successfully', {
          key: 'inbox_delete_content',
          type: 'success',
        });
      } catch (err) {
        DebuggerConsole.error('Failed to delete inbox content', err);
        ToastManager.show('Failed to delete inbox content', {
          key: 'inbox_delete_content',
          type: 'error',
        });
      } finally {
        setLoadingDeleteInboxContent(false);
      }
    }
  }

  async function updateIgnoredSenders() {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const ans = confirm('Are you sure you want to save ignored senders?');

    if (ans) {
      setIgnoredSendersLoading(true);
      try {
        await Api.Backoffice.EmailApp.updateIgnoredSenders(AKOODA_TENANT_ID, ignoredSenders);
        ToastManager.show('Updated ignored senders successfully', {
          key: 'update_ignored_senders',
          type: 'success',
        });
      } catch (err) {
        DebuggerConsole.error('Failed to update ignored senders', err);
        ToastManager.show('Failed to update ignored senders', {
          key: 'update_ignored_senders',
          type: 'error',
        });
      } finally {
        setIgnoredSendersLoading(false);
      }
    }
  }

  return (
    <div className={backofficeStyles.backofficeInternalScreen}>
      <h2>
        Email App
      </h2>
      <div className={style.content}>
        <div className={style.title}>
          Delete all inbox content
        </div>
        <div>
          <Button
            color="red"
            rounded
            padded
            onClick={deleteInboxContent}
            loading={loadingDeleteInboxContent}
          >
            Delete
          </Button>
        </div>
        <div className={style.title}>
          Ignored Senders
        </div>
        <div>
          <div>
            <IgnoredSendersInput
              disabled={ignoredSendersLoading}
              initialValue={ignoredSenders}
              setIgnoredSenders={setIgnoredSenders}
            />
          </div>
          <Button
            color="blue"
            rounded
            padded
            onClick={updateIgnoredSenders}
            loading={ignoredSendersLoading}
          >
            Save
          </Button>
        </div>
      </div>
      <Button color="blue" padded rounded onClick={endUserSession}>Logout</Button>
    </div>
  );
}
