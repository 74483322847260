/* eslint-disable react/jsx-one-expression-per-line,max-len */
import React from 'react';

import ExternalLink from 'common-ui-components/ExternalLink';

import style from 'screens/backoffice/screens/nudges/style.module.scss';

export default function SlackMarkdownInstructions() {
  return (
    <div className={style.slackMarkdownInstructions}>
      <h2>
        Slack Markdown Syntax
      </h2>
      <div>
        <section>
          <b>Supported:</b> <code>_italic_</code>, <code>*bold*</code>, <code>~strike-through~</code>, <code>```code-block```</code>, <code>`code-inline`</code>, <code>&lt; block-quotes</code> (also in multiple lines), <code>:emoji-shortcode:</code>, <code>&lt;http://link.com|link text&gt;</code>, <code>&lt;mailto:slackuser@email.com|contact name&gt;</code>, <code>&lt;@slackusername&gt;</code>.
        </section>
        <section>
          <b>Not (yet) supported:</b> <code>\n</code> (line break, just add enter instead), ordered/unordered lists (write numbers and enter between instead).
        </section>
        <section>
          In any case, you are sending a markdown message, make sure to check it first by sending it to yourself (refer to <ExternalLink to="https://api.slack.com/reference/surfaces/formatting">Slack Formatting</ExternalLink> to see more options).
        </section>
      </div>
    </div>
  );
}
