import React, { RefObject, useMemo } from 'react';

import Button from 'common-ui-components/Button';
import Api from 'global/api/platformApi';
import { isSuccessful } from 'global/api/platformApiHelpers';
import Tag from 'model/Tag';
import Tenant from 'model/Tenant';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';
import StringUtils from 'utils/StringUtils';
import ToastManager, { ToastKey } from 'utils/ToastManager';

import styles from 'screens/backoffice/screens/suggestedTags/style.module.scss';

interface Props {
  tenant: Tenant;
  selectedTags: string[];
  updatesToSuggestedTags: RefObject<Tag[]>;
  suggestedTagsToDelete: RefObject<string[]>;
  onAddToTenantsTagsSuccess: () => void;
}

export default function ActionButtons({
  tenant,
  selectedTags,
  updatesToSuggestedTags,
  suggestedTagsToDelete,
  onAddToTenantsTagsSuccess,
}: Props) {
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useMountedState(false);
  const [isUpdatingTenantTags, setIsUpdatingTenantTags] = useMountedState(false);

  async function saveSuggestedTagsStatus() {
    try {
      setIsSaveButtonLoading(true);
      await Promise.all([
        Api.TagsSet.addNewTags(tenant.id, updatesToSuggestedTags.current!, true),
        Api.TagsSet.deleteTags(tenant.id, suggestedTagsToDelete.current!, true),
      ]);
    } catch (err) {
      DebuggerConsole.error('An error has occurred while saving suggested tags status', err);
    } finally {
      setIsSaveButtonLoading(false);
    }
  }

  async function addToTenantsTags() {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const isConfirmed = confirm('Are you sure you want to add the tags?\nThis will expose the new tags to the users');
    if (isConfirmed) {
      try {
        setIsUpdatingTenantTags(true);
        const res = await Api.Backoffice.TagsSet.convertSuggestedTags(tenant.id, selectedTags);
        if (isSuccessful(res)) {
          ToastManager.show(
            'Successfully added suggested tags to tenant\'s tags',
            { key: ToastKey.ADD_SUGGESTED_TAGS_SUCCESS, type: 'success' },
          );
          onAddToTenantsTagsSuccess();
        }
      } catch (err) {
        DebuggerConsole.error('An error has occurred while adding suggested tags to tenant', err);
        ToastManager.show('Failed to add suggested tags to tenant\'s tags', { key: ToastKey.ADD_SUGGESTED_TAGS_ERROR, type: 'error' });
      } finally {
        setIsUpdatingTenantTags(false);
      }
    }
  }

  const addTagsButtonLabel = useMemo(
    () =>
      `Add ${selectedTags.length || ''} tag${selectedTags.length === 1 ? '' : 's'} to ${StringUtils.capitalize(tenant.name)}'s Tags`,
    [selectedTags, tenant],
  );

  return (
    <div className={styles.rightSection}>
      <div>
        <Button
          color="gray"
          padded
          rounded
          className={styles.bordered}
          onClick={saveSuggestedTagsStatus}
          loading={isSaveButtonLoading}
          disabled={isSaveButtonLoading}
        >
          Save
        </Button>
        <span className={styles.comment}>
          (will not be visible to users)
        </span>
      </div>
      <div>
        <Button
          color="blue"
          padded
          rounded
          onClick={addToTenantsTags}
          loading={isUpdatingTenantTags}
          disabled={selectedTags.length === 0}
        >
          {addTagsButtonLabel}
        </Button>
        <span className={styles.comment}>
          (will expose the new tags to users)
        </span>
      </div>
    </div>
  );
}
