import { Configuration } from 'model/Configuration';

export enum ConfigType {
  STRING = 'String',
  BOOLEAN = 'Boolean',
  NUMBER = 'Number',
  ARRAY = 'Array',
  OBJECT = 'Object',
}

export function getValueType(value): ConfigType {
  if (typeof value === 'string') {
    return ConfigType.STRING;
  }
  if (typeof value === 'boolean') {
    return ConfigType.BOOLEAN;
  }
  if (typeof value === 'number') {
    return ConfigType.NUMBER;
  }
  if (value instanceof Array) {
    return ConfigType.ARRAY;
  }
  return ConfigType.OBJECT;
}

export function parseValue(value: string): Configuration['value'] {
  if (['true', 'false'].includes(value)) {
    return value === 'true';
  }
  if (!Number.isNaN(parseFloat(value))) {
    return Number.parseFloat(value);
  }
  return value;
}
