import React, {
  Dispatch, SetStateAction, useRef,
} from 'react';

import SearchableSelectionDropdown from 'common-ui-components/SearchableSelectionDropdown';
import Api from 'global/api/platformApi';
import { ListOptions } from 'global/ListOptions';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

import styles from 'screens/backoffice/screens/suggestedTags/style.module.scss';

type Props = {
  selectedCategory: string | null;
  setSelectedCategory: Dispatch<SetStateAction<string | null>>;
};

export default function CategoriesFilter({
  selectedCategory,
  setSelectedCategory,
}: Props) {
  const { tenant } = useTenantContext();
  const [options, setOptions] = useMountedState<ListOptions<string | null> | null>(
    null,
  );
  const latestRequestSearch = useRef('');

  const onSearchTermChange = async (searchTerm: string) => {
    if (searchTerm.length === 0) {
      setOptions([{ label: 'All categories', value: null }]);
      return;
    }
    try {
      latestRequestSearch.current = searchTerm;
      setOptions(null);
      const res = await Api.Backoffice.TagsSet.getSuggestedTagsCategories(
        tenant.id,
        searchTerm,
      );
      if (res && latestRequestSearch.current === searchTerm) {
        setOptions(res.data.map((category) => ({ label: category, value: category })));
      }
    } catch (error) {
      DebuggerConsole.error(
        'Failed to fetch suggested tags categories options',
        error,
      );
    }
  };

  return (
    <SearchableSelectionDropdown<string | null>
      options={options}
      onSearchTermChange={onSearchTermChange}
      searchPlaceholder="Search for categories"
      selectedValue={selectedCategory}
      dropdownClassName="dropdown-menu"
      selectValue={setSelectedCategory}
      searchInputClassName={styles.filterInput}
    />
  );
}
