import React, {
  useCallback, useRef, useState,
} from 'react';

import SearchableSelectionDropdown from 'common-ui-components/SearchableSelectionDropdown';
import SearchInput from 'common-ui-components/SearchInput';
import { TagSource } from 'global/api/controller/BackofficeController';
import { RequestPaging } from 'global/api/controller/utils/commonControllerUtils';
import Api from 'global/api/platformApi';
import { ListOptions } from 'global/ListOptions';
import Tag from 'model/Tag';
import Tenant from 'model/Tenant';
import TenantSelection from 'screens/backoffice/components/TenantSelection';
import ActionButtons from 'screens/backoffice/screens/suggestedTags/ActionButtons';
import CategoriesFilter from 'screens/backoffice/screens/suggestedTags/CategoriesFilter';
import SuggestedTagsTable from 'screens/backoffice/screens/suggestedTags/SuggestedTagsTable';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import usePagedItems from 'screens/platform/cross-platform-components/Hook/pagedItemsHook';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useDebouncedString, useMountedState, useToggle } from 'utils/hooks';

import styles from 'screens/backoffice/screens/suggestedTags/style.module.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

const tagSourceOptions: ListOptions<TagSource | null> = [
  { value: null, label: 'All sources' },
  ...Object.values(TagSource).map((source) => ({
    value: source,
    label: source,
  })),
];

export default function BackofficeSuggestedTagsScreen() {
  const { tenant } = useTenantContext();
  const [data, setData] = useMountedState<Tag[]>([]);
  const [selectedTenant, setSelectedTenant] = useMountedState<Tenant>(tenant);
  const [selectedTags, setSelectedTags] = useMountedState<string[]>([]);
  const [selectedTagSource, setSelectedTagSource] = useState<TagSource | null>(
    null,
  );
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useDebouncedString();
  const [reloadDependency, triggerReload] = useToggle();

  const updatesToSuggestedTags = useRef<Tag[]>([]);
  const suggestedTagsToDelete = useRef<string[]>([]);

  const fetchTagsPage = useCallback(async (pagingConfig: RequestPaging) => {
    try {
      const response = await Api.Backoffice.TagsSet.getSuggestedTags(
        selectedTenant.id,
        {
          pagingConfig,
          source: selectedTagSource,
          category: selectedCategory,
          searchTerm,
        },
      );
      if (response?.data) {
        setData(response.data.tags);
        return { items: response.data.tags, totalItemsCount: response.data.totalTags };
      }
    } catch (err) {
      DebuggerConsole.error(
        'An error has occurred while fetching suggested tags',
        err,
      );
    }
    return null;
  }, [
    selectedTenant.id,
    selectedTagSource,
    searchTerm,
    selectedCategory,
    reloadDependency,
  ]);

  const { pagination, loading } = usePagedItems(null, fetchTagsPage);

  function removeTag(tagToRemove: string) {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const isConfirmed = confirm('Are you sure you want to remove this tag?\nThis action is irreversible');
    if (isConfirmed) {
      setData((prev) => prev.filter((tag) => tag.value !== tagToRemove));
      suggestedTagsToDelete.current.push(tagToRemove);
    }
  }

  function handleCategoryChange(tagToUpdate: Tag, nextCategory: string) {
    setData((prev) => prev.reduce((acc, tag) => {
      if (tag.value === tagToUpdate.value) acc.push({ ...tag, categories: [nextCategory] });
      else acc.push(tag);
      return acc;
    }, [] as Tag[]));

    updatesToSuggestedTags.current.push({ ...tagToUpdate, categories: [nextCategory] });
  }

  return (
    <div className={backofficeStyles.backofficeInternalScreen}>
      <h2>
        Suggested Tags
      </h2>
      <div className={backofficeStyles.tenantSelectionWrapper}>
        <label>
          For Tenant:
        </label>
        <TenantSelection
          selectedTenantId={selectedTenant.id}
          setSelectedTenant={setSelectedTenant}
          nullable={false}
        />
      </div>
      <br />
      <div className={styles.filters}>
        <SearchInput
          value={searchTerm}
          setValue={setSearchTerm}
          placeholder="Search for suggested tags"
          className={styles.filterInput}
        />
        <SearchableSelectionDropdown<TagSource | null>
          options={tagSourceOptions}
          selectedValue={selectedTagSource}
          dropdownClassName="dropdown-menu"
          selectValue={setSelectedTagSource}
          searchInputClassName={styles.filterInput}
        />
        <CategoriesFilter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
      <br />
      <div className={styles.content}>
        <div className={styles.leftSection}>
          <SuggestedTagsTable
            data={data}
            isLoading={loading}
            pagination={pagination}
            setSelectedTags={setSelectedTags}
            handleCategoryChange={handleCategoryChange}
            removeTag={removeTag}
          />
        </div>
        <ActionButtons
          tenant={selectedTenant}
          selectedTags={selectedTags}
          updatesToSuggestedTags={updatesToSuggestedTags}
          suggestedTagsToDelete={suggestedTagsToDelete}
          onAddToTenantsTagsSuccess={triggerReload}
        />
      </div>
    </div>
  );
}
