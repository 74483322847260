import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import Tenant from 'model/Tenant';
import DepartmentsColorTable from 'screens/backoffice/screens/tenant/EditTenantScreen/DepartmentsColorTable';
import OrgChartImportCSV from 'screens/backoffice/screens/tenant/EditTenantScreen/OrgChartImportCSV';
import PersonPropertiesTable from 'screens/backoffice/screens/tenant/EditTenantScreen/PersonPropertiesTable';
import TenantLogo from 'screens/backoffice/screens/tenant/EditTenantScreen/TenantLogo';
import UpdateTenantSetupState from 'screens/backoffice/screens/tenant/EditTenantScreen/UpdateTenantSetupState';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

import style from 'screens/backoffice/screens/tenant/EditTenantScreen/style.module.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

type RouteParams = {
  tenantId: string;
};

const EditTenantScreen: FC = () => {
  const {
    tenant: currentTenant,
    allTenants,
    dispatchTenant,
  } = useTenantContext();
  const { tenantId } = useParams<RouteParams>();

  const tenant = allTenants?.find((t) => t.id === Number(tenantId));

  if (!tenant) {
    return (
      <div className={backofficeStyles.backofficeInternalScreen}>
        Tenant not found!
      </div>
    );
  }

  const updateTenantDepartments = (nextDepartments: Tenant['departments']) => {
    updateTenant({
      ...tenant,
      departments: nextDepartments,
    });
  };

  const updateTenant = (changedTenant: Tenant) => {
    dispatchTenant({
      type: 'SET_ALL_TENANTS',
      payload: {
        allTenants:
          allTenants?.map((t) => {
            if (t.id === changedTenant.id) {
              return changedTenant;
            }
            return t;
          }) || [],
      },
    });
    if (currentTenant.id === changedTenant.id) {
      dispatchTenant({
        type: 'INITIALIZE',
        payload: { newTenant: changedTenant },
      });
    }
  };

  return (
    <div className={backofficeStyles.backofficeInternalScreen}>
      <h2>
        Tenants
        {' '}
        {'>'}
        {' '}
        {tenant.name}
      </h2>
      <div className={style.tablesContainer}>
        <DepartmentsColorTable
          tenantId={tenant.id}
          departments={tenant.departments}
          onColorsChange={updateTenantDepartments}
        />
        <PersonPropertiesTable personProperties={tenant.personProperties} />
      </div>
      <OrgChartImportCSV tenantId={tenant.id} />
      <div className={style.tableContainer}>
        <h4 className={style.featureHeader}>Tenant Setup State</h4>
        <UpdateTenantSetupState tenantId={tenant.id} currentState={tenant.setupState} />
      </div>
      <TenantLogo tenantId={tenant.id} />
    </div>
  );
};

export default EditTenantScreen;
