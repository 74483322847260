import React, { FC, useMemo } from 'react';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import Table from 'common-ui-components/Table';
import Size from 'global/lists/Size';
import Tenant, { PersonProperty } from 'model/Tenant';
import { useToggle } from 'utils/hooks';

import style from 'screens/backoffice/screens/tenant/EditTenantScreen/style.module.scss';

interface PersonPropertyWithId extends PersonProperty {
  id: string;
}

type Props = {
  personProperties: Tenant['personProperties'];
};

const PersonPropertiesTable: FC<Props> = ({ personProperties }) => {
  const [showTable, toggleShowTable] = useToggle(true);

  const propertiesWithId: PersonPropertyWithId[] = useMemo(
    () =>
      Object.entries(personProperties || {}).map(([id, { type }]) => ({
        id,
        type,
      })),
    [personProperties],
  );

  return (
    <div className={style.tableContainer}>
      <div className={style.tableHeaderContainer}>
        <Button className={style.featureHeader} onClick={toggleShowTable}>
          <h3>Person Properties</h3>
          <Caret size={Size.BIG} isOpen={showTable} />
        </Button>
      </div>

      {showTable && (
        <Table<PersonPropertyWithId>
          dataSource={propertiesWithId}
          rowKey="id"
          columns={[
            {
              title: 'Property',
              key: 'Property',
              render: (_, { id }) => id,
            },
            {
              title: 'Type',
              key: 'Type',
              render: (_, { type }) => type,
            },
          ]}
        />
      )}
    </div>
  );
};

export default PersonPropertiesTable;
