import React from 'react';
import { Redirect } from 'react-router-dom';

import BackofficeRouter from 'routers/BackofficeRouter';
import { DEFAULT_PLATFORM_LANDING_PAGE } from 'routers/PlatformRouter/platformRoutesConfig';
import BackofficeMenu from 'screens/backoffice/BackofficeMenu';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';

import styles from 'screens/backoffice/style.module.scss';

export default function BackofficeScreen() {
  const { isSuperAdmin } = useUserContext();

  if (!isSuperAdmin) {
    return <Redirect to={DEFAULT_PLATFORM_LANDING_PAGE} />;
  }

  return (
    <div className={styles.backofficeScreen}>
      <BackofficeMenu />
      <div className={styles.backofficeContentWrapper}>
        <BackofficeRouter />
      </div>
    </div>
  );
}
