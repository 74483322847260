import React, { useState } from 'react';

import OptionsPicker from 'common-ui-components/OptionsPicker';
import Spinner from 'common-ui-components/Spinner';
import BackofficeController from 'global/api/controller/BackofficeController';
import Tenant, { SETUP_STATE } from 'model/Tenant';
import DebuggerConsole from 'utils/DebuggerConsole';

type TenantStateOption = {
  value: SETUP_STATE;
  label: string;
};

const tenantStateOptions: TenantStateOption[] = [
  { value: SETUP_STATE.SETUP, label: 'Setup' },
  { value: SETUP_STATE.SCANNING, label: 'Scanning' },
  { value: SETUP_STATE.READY, label: 'Ready' },
];

interface Props {
  tenantId: Tenant['id'];
  currentState: Tenant['setupState'];
}

export default function UpdateTenantSetupState({ tenantId, currentState }: Props) {
  const [selectedSetupState, setSelectedSetupState] = useState(currentState);
  const [loading, setLoading] = useState(false);

  const handleSelectionChange = async (nextValue: Tenant['setupState']) => {
    try {
      setLoading(true);
      await BackofficeController.Tenant.setSetupState(tenantId, nextValue);
      setSelectedSetupState(nextValue);
    } catch (error) {
      DebuggerConsole.error('Couldn\'t set tenant status', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spinner />;

  return (
    <OptionsPicker
      options={tenantStateOptions}
      setValue={handleSelectionChange}
      selectedValue={selectedSetupState}
      disabled={loading}
    />
  );
}
