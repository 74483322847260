import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SessionResponse } from 'global/api/controller/BackofficeController';
import Api from 'global/api/platformApi';
import Role from 'global/lists/Role';
import GPTSessionBackofficeConfiguration from 'screens/backoffice/screens/GPTSessions/GPTSessionBackofficeConfiguration';
import GPTSessionsList from 'screens/backoffice/screens/GPTSessions/GPTSessionsList';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';

import style from 'screens/backoffice/screens/GPTSessions/style.module.scss';

export interface SessionSearchConfig {
  tenantId: number;
  personId?: string;
  sessionId?: string;
}

const GPT_SESSION_ID_URL_PARAM_KEY = 'sessionId';
const GPT_SESSION_TENANT_ID_URL_PARAM_KEY = 'tenantId';

export const GPT_SESSION_URL_PARAMS = [
  GPT_SESSION_ID_URL_PARAM_KEY,
  GPT_SESSION_TENANT_ID_URL_PARAM_KEY,
];

export default function GPTSessionsScreen() {
  const { roles } = useUserContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sessions, setSessions] = useState<SessionResponse[] | null>(null);
  const { search } = useLocation();

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(search);
    const sessionId = currentUrlParams.get(GPT_SESSION_ID_URL_PARAM_KEY);
    const tenantId = currentUrlParams.get(GPT_SESSION_TENANT_ID_URL_PARAM_KEY);
    if (sessionId !== null && tenantId !== null) {
      searchFunc({ tenantId: Number(tenantId), sessionId });
    }
  }, []);

  async function fetchSessions(
    { sessionId, personId, tenantId }: SessionSearchConfig,
  ): Promise<SessionResponse[]> {
    const { getSessionById, getPersonSessions, getTenantSessions } = Api.Backoffice.GPTSession;
    const emptySessions = [];
    try {
      if (sessionId) {
        const response = await getSessionById(tenantId, sessionId);
        return response?.data ? [response.data] : emptySessions;
      }
      if (personId) {
        const response = await getPersonSessions(tenantId, personId);
        return response?.data || emptySessions;
      }
      const response = await getTenantSessions(tenantId);
      return response?.data || emptySessions;
    } catch (error) {
      DebuggerConsole.error(error);
    }
    return emptySessions;
  }

  const searchFunc = async (config: SessionSearchConfig) => {
    setIsLoading(true);
    const nextSessions = await fetchSessions(config);
    const sessionsWithFixedDate = nextSessions.map(
      (session) => ({ ...session, createdAt: new Date(session.createdAt) }),
    );
    setSessions(sessionsWithFixedDate);
    setIsLoading(false);
  };

  if (!roles?.includes(Role.GPT_SESSION_VIEWER)) {
    return (
      <div>
        You don&apos;t have permissions to view GPT sessions 😭
      </div>
    );
  }

  return (
    <div className={style.screen}>
      <h2>GPT Sessions</h2>
      <GPTSessionBackofficeConfiguration search={searchFunc} isLoading={isLoading} />
      <GPTSessionsList sessions={sessions} isLoading={isLoading} />
    </div>
  );
}
