import React, { FC } from 'react';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import Table from 'common-ui-components/Table';
import Api from 'global/api/platformApi';
import Size from 'global/lists/Size';
import Tenant, { Department } from 'model/Tenant';
import ArrayUtils from 'utils/ArrayUtils';
import { useMountedState, useToggle } from 'utils/hooks';

import { ReactComponent as CheckIcon } from 'assets/img/icon/check.svg';

import style from 'screens/backoffice/screens/tenant/EditTenantScreen/style.module.scss';

enum SaveBtnState {
  IDLE,
  LOADING,
  SUCCESS,
}

type Props = {
  tenantId: Tenant['id'];
  departments: Tenant['departments'];
  onColorsChange: (nextDepartments: Tenant['departments']) => void;
};

const DepartmentsColorTable: FC<Props> = ({
  tenantId,
  departments,
  onColorsChange,
}) => {
  const [departmentsState, setDepartmentsState] = useMountedState(departments);
  const [showTable, toggleShowTable] = useToggle(true);
  const [saveBtnState, setSaveBtnState] = useMountedState(SaveBtnState.IDLE);
  const [errorMessage, setErrorMessage] = useMountedState<string | null>(null);

  async function sendData() {
    setErrorMessage(null);
    setSaveBtnState(SaveBtnState.LOADING);
    try {
      const departmentsColors = Object.entries(departmentsState).reduce(
        (acc, [departmentLabel, department]) => {
          acc[departmentLabel] = department?.color;
          return acc;
        },
        {},
      );
      const res = await Api.Backoffice.Tenant.setDepartmentsColors(
        tenantId,
        departmentsColors,
      );
      if (res?.data) {
        onColorsChange(departmentsState);
        setSaveBtnState(SaveBtnState.SUCCESS);
        setTimeout(() => {
          setSaveBtnState(SaveBtnState.IDLE);
        }, 2500);
      } else {
        throw new Error('Failed to save colors');
      }
    } catch (error) {
      setSaveBtnState(SaveBtnState.IDLE);
      // @ts-ignore
      setErrorMessage(error.message);
    }
  }

  function changeDepartmentColor(departmentId: string, newColor: string) {
    setSaveBtnState(SaveBtnState.IDLE);
    setDepartmentsState((prevDepartments) =>
      Object.entries(prevDepartments).reduce((acc, [key, value]) => {
        if (departmentId === value?.id) {
          acc[key] = { ...value, color: newColor };
        } else {
          acc[key] = value;
        }
        return acc;
      }, {}));
  }

  return (
    <div className={style.tableContainer}>
      <div className={style.tableHeaderContainer}>
        <Button className={style.featureHeader} onClick={toggleShowTable}>
          <h3>Department Colors</h3>
          <Caret size={Size.BIG} isOpen={showTable} />
        </Button>
        {showTable && (
          <div className={style.saveWrapper}>
            <Button
              color="blue"
              onClick={sendData}
              className={style.actionBtn}
              loading={saveBtnState === SaveBtnState.LOADING}
            >
              {saveBtnState === SaveBtnState.SUCCESS ? (
                <CheckIcon />
              ) : (
                'Save Colors'
              )}
            </Button>
            {errorMessage && (
              <span className={style.error}>{errorMessage}</span>
            )}
          </div>
        )}
      </div>

      {showTable && (
        <Table<Department>
          className={style.table}
          dataSource={
            Object.values(departmentsState)
              .filter(ArrayUtils.isDefined)
          }
          rowKey="id"
          columns={[
            {
              title: 'Department',
              key: 'Department',
              render: (_, { label }) => label,
            },
            {
              title: 'Color',
              key: 'Color',
              width: 100,
              render: (_, { id, color }) => (
                <input
                  type="color"
                  value={color}
                  onChange={(event) => {
                    changeDepartmentColor(id, event.target.value);
                  }}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  );
};

export default DepartmentsColorTable;
