import React, { Dispatch, SetStateAction } from 'react';

import SearchableSelectionDropdown from 'common-ui-components/SearchableSelectionDropdown';
import { ListOption } from 'global/ListOptions';
import Tenant from 'model/Tenant';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import StringUtils from 'utils/StringUtils';

import 'screens/backoffice/components/TenantSelection/style.scss';

interface NullableTenantSelectionProps {
  selectedTenantId: number | null;
  setSelectedTenant: Dispatch<SetStateAction<Tenant | null>>;
  nullable: true;
}

interface TenantSelectionProps {
  selectedTenantId: number | null;
  setSelectedTenant: Dispatch<SetStateAction<Tenant>>;
  nullable?: false;
}

export default function TenantSelection({
  selectedTenantId,
  setSelectedTenant,
  nullable = false,
}: NullableTenantSelectionProps | TenantSelectionProps) {
  const { allTenants } = useTenantContext();

  function handleTenantSelection(nextTenantId: number | null) {
    const nextTenant = nextTenantId && allTenants?.find(({ id }) => id === nextTenantId);

    if (nullable) {
      (setSelectedTenant as NullableTenantSelectionProps['setSelectedTenant'])(nextTenant || null);
    } else if (nextTenant) {
      setSelectedTenant(nextTenant);
    }
  }

  let options: ListOption<number | null>[] = ((allTenants || []).map(({ id, name }) => (
    { value: id, label: StringUtils.capitalize(name) }
  )));
  if (nullable) {
    options = [{ value: null, label: 'All Tenants' }, ...options];
  }

  return (
    <div className="tenant-selection-wrapper">
      <SearchableSelectionDropdown<number | null>
        options={options}
        selectedValue={selectedTenantId}
        dropdownClassName="dropdown-menu"
        selectValue={handleTenantSelection}
      />
    </div>
  );
}
