import React, { ChangeEvent, useEffect, useRef } from 'react';

import Button from 'common-ui-components/Button';
import Api from 'global/api/platformApi';
import HttpStatus from 'global/lists/HttpStatus';
import Tenant from 'model/Tenant';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

import editTenantStyles from 'screens/backoffice/screens/tenant/EditTenantScreen/style.module.scss';
import style from 'screens/backoffice/screens/tenant/EditTenantScreen/TenantLogo/style.module.scss';

const ACCEPTED_FILE_TYPES = 'image/png, image/svg+xml';

enum GeneralError {
  NO_FILE_SELECTED = 'No image file selected.',
  INVALID_FILE_TYPE = 'Invalid file type. Please select a valid image file.',
  FAILED_TO_UPLOAD_FILE = 'Failed to upload file',
}

type Props = {
  tenantId: Tenant['id'];
};

export default function TenantLogo({ tenantId }: Props) {
  const [selectedFile, setSelectedFile] = useMountedState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [currentLogoUrl, setCurrentLogoUrl] = useMountedState<string | null>(null);
  const [generalError, setGeneralError] = useMountedState<GeneralError | null>(null);
  const [loading, setLoading] = useMountedState(false);

  useEffect(() => {
    fetchTenantLogo();
  }, []);

  const fetchTenantLogo = async () => {
    try {
      const response = await Api.Image.getLogo(tenantId);
      if (response?.data) {
        const url = response.data.size > 0 ? URL.createObjectURL(response.data) : null;
        setCurrentLogoUrl(url);
      }
    } catch (error) {
      DebuggerConsole.error('Couldn\'t load tenant logo', error);
    }
  };

  const uploadLogo = async () => {
    if (!selectedFile) {
      setGeneralError(GeneralError.NO_FILE_SELECTED);
      return;
    }

    try {
      setLoading(true);
      const res = await Api.Backoffice.Tenant.updateLogo(
        tenantId,
        selectedFile,
      );
      if (res?.status === HttpStatus.OK) {
        await fetchTenantLogo();
        if (inputFileRef.current) {
          inputFileRef.current.value = '';
        }
      }
    } catch (error) {
      DebuggerConsole.error('Failed to upload tenant logo from Backoffice', error);
      setGeneralError(GeneralError.FAILED_TO_UPLOAD_FILE);
    } finally {
      setLoading(false);
      setSelectedFile(null);
    }
  };

  function onFileSelect(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.item(0);
    if (file) {
      if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
        setGeneralError(GeneralError.INVALID_FILE_TYPE);
        return;
      }
      setSelectedFile(file);
      setGeneralError(null);
    }
  }

  return (
    <div className={style.addTenantLogo}>
      <div className={style.titleWithLogo}>
        <h4 className={style.title}>Tenant Logo</h4>
        {currentLogoUrl && <img src={currentLogoUrl} alt="tenant's logo" className={style.img} />}
      </div>
      <div className={style.inputWrapper}>
        <input
          ref={inputFileRef}
          type="file"
          accept={ACCEPTED_FILE_TYPES}
          onChange={onFileSelect}
        />
        <Button
          onClick={uploadLogo}
          color="blue"
          className={editTenantStyles.actionBtn}
          loading={loading}
        >
          Update Logo
        </Button>
      </div>
      {generalError && (
        <span className={editTenantStyles.error}>{generalError}</span>
      )}
    </div>
  );
}
