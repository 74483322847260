import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import Button from 'common-ui-components/Button';
import Input from 'common-ui-components/Input';
import Modal from 'common-ui-components/Modal';
import SearchableSelectionDropdown from 'common-ui-components/SearchableSelectionDropdown';
import Api from 'global/api/platformApi';
import { AKOODA_TENANT_ID } from 'global/constants';
import {
  areConfigsIdentical,
  Configuration,
  ConfigurationMetadata,
  NewlyCreatedConfiguration,
} from 'model/Configuration';
import Tenant from 'model/Tenant';
import AkoodaEmployeeSelection from 'screens/backoffice/components/AkoodaEmployeeSelection';
import TenantSelection from 'screens/backoffice/components/TenantSelection';
import { parseValue } from 'screens/backoffice/screens/configuration/configurationUtils';

import style from 'screens/backoffice/screens/configuration/style.module.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  allConfigs: null | Configuration[];
  saveNewConfig: (config: NewlyCreatedConfiguration) => void;
  allAkoodaEmployees: { _id: string; name: string }[];
}

enum ConfigUpdateError {
  EMPTY_KEY = 'Config key can\'t be empty',
  EMPTY_VALUE = 'Config value can\'t be empty',
  EMPTY_KEY_AND_VALUE = 'Both key and value fields can\'t be empty',
}

export default function AddConfigurationModal({
  isOpen, setIsOpen, allConfigs, allAkoodaEmployees, saveNewConfig,
}: Props) {
  const [configKey, setConfigKey] = useState('');
  const [configValue, setConfigValue] = useState('');
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [configPersonId, setConfigPersonId] = useState<string | null>(null);
  const [bottomMessage, setBottomMessage] = useState<string | null>(null);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const configKeysOptions = useMemo(() => {
    if (allConfigs) {
      const uniqueConfigsKeys = [...new Set(allConfigs.map((config) => config.key))].sort();
      return uniqueConfigsKeys.map((key) => ({ value: key, label: key }));
    }
    return null;
  }, [allConfigs]);

  useEffect(() => {
    setBottomMessage(null);
    setIsSuccessful(false);
  }, [configKey, configValue, tenant]);

  function onSubmit() {
    const isKeyEmpty = configKey.trim().length === 0;
    const isValueEmpty = configValue.length === 0;

    function getErrorMessage() {
      if (isKeyEmpty && isValueEmpty) return ConfigUpdateError.EMPTY_KEY_AND_VALUE;
      if (isKeyEmpty) return ConfigUpdateError.EMPTY_KEY;
      if (isValueEmpty) return ConfigUpdateError.EMPTY_VALUE;
      return null;
    }

    if (isKeyEmpty || isValueEmpty) {
      setBottomMessage(getErrorMessage());
    } else {
      const currentConfigMetadata: ConfigurationMetadata = {
        personId: undefined,
        key: configKey.trim(),
        _tid: tenant?.id || null,
      };
      const isOverridingConfig = allConfigs?.some(
        (config) => areConfigsIdentical(config, currentConfigMetadata),
      );
      if (isOverridingConfig) {
        // eslint-disable-next-line no-restricted-globals,no-alert
        const shouldOverride = confirm('Are you sure you want to override this configuration?');
        if (!shouldOverride) return;
      }

      const newConfig: NewlyCreatedConfiguration = {
        key: configKey.trim(),
        value: parseValue(configValue),
        _tid: tenant?.id || null,
        personId: configPersonId,
      };

      Api.Backoffice.Config.setConfig(newConfig)
        .then((res) => {
          setBottomMessage(res?.data || null);
          setIsSuccessful(true);
          saveNewConfig(newConfig);
          setTimeout(() => setIsOpen(false), 1000);
        })
        .catch((err) => {
          setBottomMessage(err.message);
        });
    }
  }

  return (
    <Modal
      className={backofficeStyles.backofficeModalContent}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <h2>Add a new configuration</h2>
      <div className={backofficeStyles.formGrid}>
        <span>
          Key
        </span>
        <SearchableSelectionDropdown
          options={configKeysOptions}
          selectedValue={configKey}
          selectValue={setConfigKey}
          acceptCustomValues
          transparentSearchInput
        />
        <span>
          Value
        </span>
        <Input
          placeholder="Add a new configuration"
          value={configValue}
          setValue={(next: string) => setConfigValue(next)}
          transparent
        />
        <span>
          Tenant
        </span>
        <TenantSelection
          selectedTenantId={tenant?.id || null}
          setSelectedTenant={setTenant}
          nullable
        />
        {
          tenant?.id === AKOODA_TENANT_ID && (
            <>
              <span>
                Person
              </span>
              <AkoodaEmployeeSelection
                selectedPersonId={configPersonId}
                setSelectedPersonId={setConfigPersonId}
                allAkoodaEmployees={allAkoodaEmployees}
              />
            </>
          )
        }
      </div>
      <Button color="blue" padded rounded onClick={onSubmit} type="submit">
        Submit
      </Button>
      <div
        className={classNames(
          style.bottomMessage,
          { [style.errorMessage]: Boolean(bottomMessage) && !isSuccessful },
        )}
      >
        {bottomMessage}
      </div>
    </Modal>
  );
}
